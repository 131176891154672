import { createAction, props } from '@ngrx/store';

export const setProductsToCompare = createAction('[Product Compare] List Added ', props<{ styleNumber: string[] }>());
export const addProductToCompare = createAction(
  '[Product Compare] Add Product Style To Compare List',
  props<{ styleNumber: string }>()
);

export const removeAllProductsFromCompare = createAction('[Product Compare] Remove All Compare Products');

export const removeProductFromCompare = createAction(
  '[Product Compare] Remove Product Style Based On Style Number',
  props<{ styleNumber: string }>()
);
